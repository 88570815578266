import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Projects`}</h1>
    <br />
    <ProjectCard bg="#FBB03B" mdxType="ProjectCard">
    <h3>This Website</h3>
    Stack: GatsbyJS, React, Netlify, Git
    </ProjectCard>
    <ProjectCard link="https://careerreadyberks.org/" bg="#f011a5" mdxType="ProjectCard">
    <h3>Career Ready Berks</h3>
    Stack: C# .Net, jQuery, Azure DevOps, MSSQL
    </ProjectCard>
    <ProjectCard link="https://www.bmwusatires.com/" bg="#1d1b94" mdxType="ProjectCard">
    <h3>BMW Aftersale Tires</h3>
    Stack: HTML, JavaScript, C# .NET, Git
    </ProjectCard>
    <ProjectCard link="https://www.miniusatires.com/" bg="#5eb051" mdxType="ProjectCard">
    <h3>Mini Aftersale Tires</h3>
    Stack: HTML, JavaScript, C# .NET, Git
    </ProjectCard>
    <ProjectCard link="https://rb.gy/dzyqdq" bg="#D4145A" mdxType="ProjectCard">
    <h3>College Game Dev Projects</h3>
    Stack: Unity, C#
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      