import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Scott Robertson's Site",
  "date": "2020-11-11T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img src={`../../profile.jpg`} alt="Scott's Profile Image" width="200" height="200" style={{
      "marginLeft": "auto",
      "marginRight": "auto",
      "borderRadius": "50%",
      "display": "block"
    }} />
    <h1>{`Hi, I'm Scott Robertson`}</h1>
    <p>{`I am a web developer who specializes in full-stack development with a strong focus on back-end technologies`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      