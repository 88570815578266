import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About`}</h1>
    <p>
    From the beginning, I have always had a passion for technology. 
    Once my mother sat me in front of a TV with a NES plugged in, it was over. I became a nerd that was fascinated in video games and tech.
    That fascination brought me to study programming in high school and learn best practices when it comes to coding.
    </p>
    <p>
    After high school, I went to Pennsylvania College of Technology to study game development. 
    While we learned the complex aspects of creating games, the degree's cirriculum also covered the foundations of software development. 
    In 2015, I graduated from Penn College with a Bachelors in Game and Simulation Development.
    </p>
    <p>
    From then on, I have applied the knowledge that I gained from school to craft robust web applications, mainly utilizing the .NET Framework,
    in my professional roles. While my work has mostly focused on using .NET, I adapt to whatever the job deems necessary and try to learn new things whenever possible.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      