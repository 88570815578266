import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Contact`}</h1>
    <h5>{`Get in touch with me on`}<Link to="https://www.linkedin.com/in/scott-robertson-9b356087/" mdxType="Link">{` LinkedIn `}</Link></h5>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      